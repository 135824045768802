.btn-arrow-right::before {
    content: '';
    position: absolute;
    right: -9px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 11px solid #298ccc;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
}
button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}
.pointer {
    width: 200px;
    height: 75px;
    background-image: url('../../assets/images/topic-sample.png');
    position: relative;
}
.pointer:after {
    content: '';
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-image: url('../../assets/images/topic-sample.png') 30 / 19px round;
    border-left: 20px solid #000;
    border-top: 37px solid transparent;
    border-bottom: 37px solid transparent;
}
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: #e8e8e8;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}
.slider:hover {
    opacity: 1;
}
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #173463;
    cursor: pointer;
}
.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #173463;
    cursor: pointer;
}
.morphii-container.morphii-single-item .morphii-list .morphii-item {
    margin-left: 230px;
}

.reflection-morphii > .morphii-container.morphii-single-item .morphii-list .morphii-item {
    margin-left: 194.63px;
    margin-left: 230px;
}

.morphii-container.morphii-single-item .morphii-list .morphii-svg-container svg {
    width: 89px !important;
    height: 89px !important;
    display: block !important;
    margin: auto !important;
}
.morphii-list {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
    text-align: center;
    margin-top: 0px !important;
    padding: 15.3px 0px !important;
}

.reflection-morphii > div#morphii-slider {
    border-radius: 26px;
    border: none;
    background: #edeeef;
    margin-top: 45px;
     height: 50px !important;
}


.schedule-morphii > .morphii-container .morphii-slider-input-container .morphii-slider-container .morphii-slider {
    height: 50px !important;
    border-radius: 26px;
    border: none;
    background: #edeeef;
}

.noUi-handle.noUi-handle-lower {
    background-image: url('../../assets/images/scroll-icon.svg') !important;
    border-radius: 50% !important;
    top: 0 !important;
    height: 44px !important;
    width: 44px !important;
    margin-top: 2.5px;
    background-repeat: no-repeat !important;
}

.morphii-container.morphii-single-item .morphii-list .morphii-svg-container {
    display: block;
    margin-top: -30px;
}

 div#morphii-slider {
    border-radius: 26px;
    border: none;
    background: #EDEEEF;
    margin-top: 45px;
}

 .reflection-morphii > .morphii-container .morphii-slider-input-container .morphii-slider-container .morphii-slider {
    height: 50px;
    border-radius: 26px;
    border: none;
    background: #EDEEEF;
    margin-top: 45px;
}

.schedule-morphii > .morphii-container .morphii-slider-input-container .morphii-slider-container .morphii-slider {
    height: 50px;
    border-radius: 26px;
    border: none;
    background: #EDEEEF;
    margin-top: 0px !important;
}


.morphii-container.morphii-single-item .morphii-list {
    padding: 0px !important;
    padding-bottom: 5px !important;
}

.morphii-container .noUi-horizontal .noUi-base {
    width: calc(100% - 45px);
}

.morphii-container
    .morphii-slider-input-container
    .morphii-slider-container
    .morphii-slider-left-label {
    max-width: 60px;
    margin-right: 0px;
    font-size: 13px;
    line-height: 18px;
}

.slide-bg {
    background-color: rgba(0, 94, 255, 0.07);
}

.sliderArea {
    width: 300px;
    margin: 0 auto;
}
.rc-slider-step {
    position: absolute;
    width: 100%;
    height: 1px;
    background: #8c8c8c;
}
.rc-slider-handle.rc-slider-handle-2 {
    position: absolute;
    width: 22px;
    height: 22px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -10px;
    cursor: grab;
    border-radius: 50%;
    border: transparent !important;
    background-color: #0086c9;
    touch-action: pan-x;
    z-index: 2;
}
.rc-slider-track.rc-slider-track-1 {
    position: absolute;
    left: 0;
    height: 23px;
    border-radius: 6px;
    background-color: #173463;
    z-index: 1;
    margin-top: -10px;
}
.rc-slider-handle.rc-slider-handle-1 {
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -10px;
    cursor: grab;
    border-radius: 50%;
    border: transparent !important;
    background-color: #0086c9;
    touch-action: pan-x;
    z-index: 2;
}
.rc-slider-tooltip-arrow {
    border-top-color: transparent !important;
}
.rc-slider-tooltip-inner {
    color: #173463 !important;
    background: none !important;
}

.select-box {
    display: block;
    font-size: 18px;
    font-weight: 400;
    padding: 0 1.4em 0 0.75rem;
    width: 100%;
    height: 3.125rem;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    outline: 0px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='11' viewBox='0 0 19 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5 9.5L1.5 1.5' stroke='%231D1183' stroke-width='2' stroke-linecap='square'/%3E%3Cpath d='M9.5 9.5L17.5 1.5' stroke='%231D1183' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");

    /* background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 20010904//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='292.000000pt' height='292.000000pt' viewBox='0 0 292.000000 292.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cg transform='translate(0.000000 292.000000) scale(0.100000 -0.100000)'fill='%233C3C3C' stroke='none'%3E%3Cpath d='M127 2265 c-92 -32 -141 -124 -117 -217 10 -39 79 -111 673 -706 443-443 675 -669 703 -682 45 -22 98 -26 141 -9 36 13 1358 1333 1378 1375 35 734 175 -68 222 l-41 27 -1315 2 c-1097 2 -1322 0 -1354 -12z'/%3E%3C/g%3E%3C/svg%3E"); */
    /* background-image: url("data:image/svg+xml,%3C%3Fxmlversion='1.0'standalone='no'%3F%3E%3C!DOCTYPEsvgPUBLIC'-//W3C//DTD SVG 20010904//EN''http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg version='1.0'xmlns='http://www.w3.org/2000/svg'width='292.000000pt'height='292.000000pt'viewBox='0 0 292.000000 292.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cg transform='translate(0.000000 292.000000) scale(0.100000 -0.100000)'fill='%233C3C3C' stroke='none'%3E%3Cpath d='M127 2265 c-92 -32 -141 -124 -117 -217 10 -39 79 -111 673 -706 443-443 675 -669 703 -682 45 -22 98 -26 141 -9 36 13 1358 1333 1378 1375 35 734 175 -68 222 l-41 27 -1315 2 c-1097 2 -1322 0 -1354 -12z'/%3E%3C/g%3E%3C/svg%3E"); */
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1em auto, 100%;
}
