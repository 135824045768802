.border-color-hr {
    border-color: #979797;
}

div.wrap {
    overflow: hidden;
}
section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transition: all 1s ease-in-out;
}

/* // SET TO RELATIVE AFTER TRANSITION */
section.route-section.transition-wrap-enter-done {
    position: relative;
}

.page-wrap {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;
    margin-top: 138px;
}
.transition-wrap {
    position: absolute;
    left: 15px;
    right: 15px;
    transition: all 1s ease-in-out;
}

.transition-group.left .transition-wrap-enter {
    position: absolute;
    /* opacity: 0; */
    transform: translate3d(100%, 0, 0);
}

.left .transition-wrap-enter-active {
    /* opacity: 1; */
    transform: translate3d(100%, 0, 0);
    transition: opacity 300ms, transform 3s;
}

.left .transition-wrap-exit {
    position: absolute;
    /* opacity: 1; */
    transform: translate3d(-100%, 0, 0);
}

.left .transition-wrap-exit-active {
    position: absolute;
    /* opacity: 0; */
    transform: scale(0.9);
    transform: translate3d(-100%, 0, 0);
}

/* // right */
.right .transition-wrap-enter {
    position: absolute;
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

.right .transition-wrap-enter-active {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
    transition: opacity 300ms, transform 3s;
}

.right .transition-wrap-exit {
    position: absolute;
    opacity: 1;
    transform: translate3d(100%, 0, 0);
}

.right .transition-wrap-exit-active {
    position: absolute;
    opacity: 0;
    transform: scale(0.9);
    transform: translate3d(100%, 0, 0);
}

@keyframes slideInFromRight {
    0% {
        transform: translate3d(100%, 0, 0);
    }
    100% {
        transform: translate3d(0%, 0, 0);
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translate3d(0%, 0, 0);
    }
    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes slideOutFromLeft {
    0% {
        transform: translate3d(0%, 0, 0);
    }
    100% {
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slideOutFromRight {
    0% {
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        transform: translate3d(0%, 0, 0);
    }
}

.slide-right-drill {
    animation: 0.3s ease-out 0s 1 slideInFromRight forwards;
}

.slide-left-drill {
    animation: 0.3s ease-out 0s 1 slideInFromLeft forwards;
}

.slide-left-out-drill {
    animation: 0.3s ease-out 0s 1 slideOutFromLeft forwards;
}
.slide-right-out-drill {
    animation: 0.3s ease-out 0s 1 slideOutFromRight forwards;
}

@keyframes pulse {
    0% {
        box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
    }
    65% {
        box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
    }
    90% {
        box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
    }
}

.rec {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes bottom-slidein {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}
.bottom-transition {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 0.5s ease-out 0s 1 bottom-slidein forwards;
}

@keyframes up-slidein {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0%);
    }
}

.up-transition-in {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 0.5s ease-out 0s 1 up-slidein forwards;
}

@keyframes up-slideout {
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(-100%);
    }
}

.up-transition-out {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 0.5s ease-out 0s 1 up-slideout forwards;
}

.landing-bg {
    background-image: url('../../assets/images/landing-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

/* These styles are used if a demo specific stylesheet is not present */

.stripe-Label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
    margin-top: 16px;
    display: block;
}

.add-payment-button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.add-payment-button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.add-payment-button[disabled] {
    opacity: 0.6;
}

#postal {
    display: block;
    border: none;
    font-size: 18px;
    margin: 10px 0 20px 0;
    max-width: 100%;
    padding: 14px !important;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
    color: #424770;
    letter-spacing: 0.025em;
    width: 500px;
    height: 40px;
}

#postal::placeholder {
    color: #aab7c4;
    padding-left: 14px;
    font-weight: 600;
}

.result,
.error {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
}

.error {
    color: #e4584c;
}

.result {
    color: #666ee8;
}

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
    display: block;
    margin: 6px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    border-radius: 4px;
    background: white;
/* Kindly Gray 1 */

border: 1px solid #D8D8D8;
border-radius: 5px;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.loading {
    height: 41.6px;
    opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
    padding: 0;
}

.antiClockwiseSpin {
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: antiClockwiseSpin;
    animation-timing-function: linear;
}
@keyframes antiClockwiseSpin {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.antiClockwiseSpinLoader {
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-name: antiClockwiseSpin;
    animation-timing-function: linear;
}
@keyframes antiClockwiseSpin {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.clockwiseSpin {
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-name: clockwiseSpin;
    animation-timing-function: linear;
}
@keyframes clockwiseSpin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* disable spin buttons for inout type number */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
/* blog line clamps */

.lineclamps-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.lineclamps {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.lineclamps-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.lineclamps-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
}

html {
    scroll-behavior: smooth;
}

.make-connection-scroll {
    margin-left: 23px !important;
}
.make-connection-scroll-last {
    margin-left: -23px !important;
}

/* .home-horizontal {
    width: 325px !important;
} */
